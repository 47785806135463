/* eslint-disable no-confusing-arrow */
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { authorizedGet } from '../../utils/fetchUtils/authorizedGet'
import { queryKeys } from './QueryKeys'


export const queryKey = queryKeys.useGetOwnerInfo

export type OwnerInfo = {
	name: string
	address1: string
	address2: string
	postalCode: string
	city: string
	phoneNumber: string
	corporateRegistrationNumber: string
	bankgiroNumber: string
	plusgiroNumber: string
	vatIdentificationNo: string
	emailAddress: string
}

type UseGetOwnerInfoParams = {
	queryOptions?: UseQueryOptions<OwnerInfo, unknown>
	additionalQueryKeys?: unknown[]
}

export const useGetOwnerInfo = (
	options: UseGetOwnerInfoParams = {
		queryOptions: {},
		additionalQueryKeys: []
	}
) => {
	const { queryOptions, additionalQueryKeys = [] } = options

	return useQuery({
		queryKey: [queryKey, ...additionalQueryKeys],
		queryFn: () => authorizedGet<OwnerInfo>('/api/v2/Info/OwnerInfo', ),
		staleTime: 1000 * 60 * 60, // Cache for 60 minutes since owner info rarely changes
		...queryOptions
	})
}
