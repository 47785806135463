/* eslint-disable no-restricted-syntax */
/* eslint-disable func-style */
import React, { useState } from 'react'
import { CardModel } from '../../domain/models/CardModel'
import { usePutCardPin } from '../../hooks/queryHooks/usePutCardPin'
import Button from '../elements/Button'
import Icon from '../elements/Icon'
import InlineAlert from '../elements/InlineAlert'
import Label from '../elements/Label'

type Props = {
  card: CardModel
  onCancel: () => void
}

const CardNewPin: React.FC<Props> = ({ card, onCancel }) => {
  const newPinMutation = usePutCardPin({
    extendedOnSuccessFunction: onCancel
  })

  const [newPinInputs, setPinInputs] = useState({
    newPin: {
      value: '',
      error: ''
    },
    newPinVerify: {
      value: '',
      error: ''
    }
  })

  const handleSave = () => {
    newPinMutation.mutate({
      cardName: card.cardReference1,
      cardNo: card.cardNo,
      newPin: newPinInputs.newPin.value
    })
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name = event.currentTarget.name as keyof typeof newPinInputs
    const { value } = event.currentTarget

    const newInputs = {
      ...newPinInputs,
      [name]: { ...newPinInputs[name], value }
    }
    const { newPin, newPinVerify: newPinValidation } = newInputs

    newInputs.newPin.error = pinInputError(newPin.value)
    newInputs.newPinVerify.error = pinInputVerifyError(
      newPin.value,
      newPinValidation.value
    )

    setPinInputs(newInputs)
  }

  const isSubmitDisabled = () => {
    const { newPin, newPinVerify } = newPinInputs

    if (!newPin.value || !newPinVerify.value) return true
    if (newPin.value !== newPinVerify.value) return true
    if (newPin.error || newPinVerify.error) return true

    return false
  }

  let cardContainerClasses = 'Card Card--Editor';
  if (card?.isSmartCard) {
    cardContainerClasses += ' smartcard'
  }
  return (
    <div className={cardContainerClasses}>
      <h6 className="Card__Heading">
        <Icon name="lock" primaryColor="blue400" secondaryColor="green500" />
        {'Byt pinkod'}
      </h6>
      <p className="Card__Subheading">{card.cardReference1}</p>
      <Label className="Card__InputRow" text="Ny kod:">
        <input
          className="Card__Input"
          disabled={newPinMutation.isLoading}
          maxLength={4}
          name="newPin"
          onChange={handleInputChange}
          value={newPinInputs.newPin.value}
        />
        <InlineAlert content={newPinInputs.newPin.error} />
      </Label>
      <Label className="Card__InputRow" text="Verifiera ny kod:">
        <input
          className="Card__Input"
          disabled={newPinMutation.isLoading}
          maxLength={4}
          name="newPinVerify"
          onChange={handleInputChange}
          value={newPinInputs.newPinVerify.value}
        />
        <InlineAlert content={newPinInputs.newPinVerify.error} />
      </Label>
      <div className="Card__ActionButtons">
        <Button
          className="Button Card__Button Card__Button--Change"
          disabled={isSubmitDisabled() || newPinMutation.isLoading}
          onClick={handleSave}
          type="button"
        >
          {'Byt kod'}
        </Button>
        <button
          className="Button Card__Button Card__Button--Cancel"
          onClick={() => onCancel()}
          type="button"
        >
          {'Avbryt'}
        </button>
      </div>
      {newPinMutation.isError && (
        <div className="Card__SaveError">
          {'Det gick inte att uppdatera pinkoden. Vänligen försök igen'}
        </div>
      )}
    </div>
  )
}

export default CardNewPin

export function pinInputError(newPin: string) {
  if (!newPin) {
    return 'Fältet är obligatoriskt'
  }

  if (newPin.match(/^[0-9]+$/) === null) {
    return 'Koden får enbart bestå av siffror'
  }

  if (newPin.length !== 4) {
    return 'Koden måste vara exakt 4 siffror'
  }

  const pin: string = newPin
  if (/(.)\1\1\1/.test(pin)) {
    return 'Koden måste innehålla olika siffror'
  }

  const sequence = '0123456789 9876543210'
  if (sequence.includes(pin)) {
    return 'Nummerföljder är inte tillåtet'
  }

  return ''
}

export function pinInputVerifyError(newPin: string, oldPin: string) {
  if (!newPin) {
    return 'Fältet är obligatoriskt'
  }
  if (oldPin !== newPin) {
    return 'Koden är inte samma'
  }

  return ''
}
