import React, { Suspense } from 'react'
import { CardModel } from '../../domain/models/CardModel'
import Icon from '../elements/Icon'
import CardReplacementForm from './CardReplacementForm'

type Props = {
  card: CardModel
  onCancel: () => void
}

const CardReplacement: React.FC<Props> = ({ card, onCancel }) => {
  let cardContainerClasses = 'Card Card--Editor';
  if (card?.isSmartCard) {
    cardContainerClasses += ' smartcard'
  }
  return (
    <div className={cardContainerClasses}>
      <h6 className="Card__Heading">
        <Icon name="lock" primaryColor="blue400" secondaryColor="green500" />
        {'Beställ ersättningskort'}
      </h6>
      <p className="Card__Subheading">{card.cardReference1}</p>
      <Suspense fallback={<p>{'Laddar kort!'}</p>}>
        <CardReplacementForm card={card} onCancel={onCancel} />
      </Suspense>
    </div>
  )
}

export default CardReplacement
