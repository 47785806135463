import React, { useEffect, useState } from 'react';
import { useGetCustomer } from '../../../hooks/queryHooks/useGetCustomer';
import useIsBankIdUser from '../../../hooks/useIsBankIdUser';
import { int32 } from '../../../integrations/OpenAPI';
import Link from '../../elements/Link';
import RightsButton from '../../elements/RightsButton';
import { useCustomerStatus } from '../../hocs/GetCustomerStatus';

type CardManagementProps = {
	data?: any // TODO: Change this
}

const CardManagement: React.FC<CardManagementProps> = ({data}) => {
	const customer = useGetCustomer()
	const [showAnimationClass, setshowAnimationClass] = useState(false)
	const isBankIdUser = useIsBankIdUser()
	const hasSmartCards = customer.data?.hasActivePrepaidCard
	const hasCreditCards = customer.data?.hasActiveCreditCard
	const { customerAllowedToWriteBool } = useCustomerStatus( customer.data );
	
	/**
	 * connectedServices
	 * 
	 * Checks the number of services connected.
	 * 
	 * @returns string | int32
	 */
	const connectedServices = () => {
		let numberOfCardsText: string | int32 = 'Du har inga'

		if ( customer.data?.hasActiveCreditCard ) {
			numberOfCardsText = 1
		}

		if ( customer.data?.hasActivePrepaidCard ) {
			if ( 'string' === typeof(numberOfCardsText) ) {
				numberOfCardsText = 0
			}
			numberOfCardsText +=1
		}

		if ( customer.data?.bankCardCustomer ) {
			if ( 'string' === typeof(numberOfCardsText) ) {
				numberOfCardsText = 0
			}
			numberOfCardsText +=1
		}

		return numberOfCardsText;
	}

	// Handle cleanup and removal of the class
	useEffect(() => {
		if (showAnimationClass) {
			const timeoutId = setTimeout(() => {
				setshowAnimationClass(false);
			}, 2250);

			// Cleanup function
			return () => clearTimeout(timeoutId);
		}
	}, [showAnimationClass]);

	// Toggle the class on click
	const toggleAnimationClass = () => {
		setshowAnimationClass(true);
	};

	return(
		<>
			<div className="card-info">
				<p className="p-size-medium">{'Dina anslutna korttjänster'}</p>
				<p>
					{connectedServices()} {connectedServices() == 1 ? 'kopplad korttjänst' : 'kopplade korttjänster'}
					<br />
					<Link className="small-link" ariaLabel={'Mina kort, kortöversikt'} href={'/kort'} styled>{'Kortöversikt'}</Link>
				</p>
				<div className="card-info__transactions">
					<p aria-label='Information relaterat till transaktioner'>
						{'Visa transaktioner för specifikt kort genom att klicka på '}
						<Link className="small-link" onClick={toggleAnimationClass} href={'#'} styled>kortet</Link>
						{' till höger'}
					</p>
				</div>
			</div>
			<div className={`icon-wrapper ${showAnimationClass ? 'animate-icons' : ''}`}>
					{customer.data?.hasActivePrepaidCard && (
						<div>
							<Link ariaLabel={'Kortöversikt smartkort'} href={'/fakturor-kontobesked/smartkort'}>
								<svg width="800px" height="800px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#000000">
									<g id="SVGRepo_bgCarrier" stroke-width="0"/>
									<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
									<g id="SVGRepo_iconCarrier">
										<path d="M32 2C15.432 2 2 15.431 2 32c0 16.569 13.432 30 30 30s30-13.432 30-30C62 15.431 48.568 2 32 2m8.953 42.678c-2.049 1.752-4.943 2.627-8.684 2.627c-3.82 0-6.826-.863-9.014-2.588c-2.189-1.727-3.283-4.098-3.283-7.117h5.787c.188 1.326.557 2.316 1.105 2.973c1.006 1.195 2.727 1.791 5.166 1.791c1.461 0 2.646-.156 3.557-.473c1.73-.604 2.594-1.725 2.594-3.365c0-.957-.424-1.699-1.27-2.225c-.848-.512-2.191-.965-4.029-1.357l-3.141-.689c-3.088-.684-5.209-1.424-6.363-2.224c-1.957-1.339-2.934-3.432-2.934-6.28c0-2.599.957-4.757 2.869-6.476c1.912-1.72 4.723-2.579 8.43-2.579c3.096 0 5.734.81 7.922 2.431c2.184 1.621 3.33 3.974 3.438 7.058h-5.828c-.107-1.745-.887-2.985-2.34-3.721c-.969-.485-2.174-.729-3.613-.729c-1.602 0-2.879.315-3.834.945s-1.434 1.509-1.434 2.638c0 1.037.471 1.811 1.414 2.322c.604.342 1.889.742 3.855 1.201l5.092 1.201c2.23.524 3.904 1.227 5.018 2.105c1.729 1.365 2.594 3.341 2.594 5.925c0 2.651-1.023 4.854-3.074 6.606" fill="#ff0000"/>
									</g>
								</svg>	
							</Link>
							<p>{'Smartkort'}</p>
						</div>
					)}

				{customer.data?.hasActiveCreditCard && (
					<div>
						<Link ariaLabel={'Kortöversikt tankkort'} href={'/fakturor-kontobesked/tankkort'}>
							{customer.data?.isCompany ? (
								<svg width="800px" height="800px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet">
									<path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm10.345 20.61H27.68v6.673h12.854v5.039H27.68v12.166h-6.024V17.512h20.689v5.098z" fill="#505050"></path>
								</svg>
							) :(
								<svg width="800px" height="800px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#000000">
								<g id="SVGRepo_bgCarrier" stroke-width="0"/>
								<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
								<g id="SVGRepo_iconCarrier">
									<path d="M32 2C15.432 2 2 15.431 2 32c0 16.569 13.432 30 30 30s30-13.432 30-30C62 15.431 48.568 2 32 2m11.754 20.629h-8.682v23.878h-6.104V22.629h-8.721v-5.138h23.506v5.138z" fill="#27367a"/>
								</g>
							</svg>
							)}
							
							
						</Link>
						<p>{customer.data?.isCompany ? 'Företagskort' : 'Tankkort'}</p>
					</div>
				)}

				{customer.data?.bankCardCustomer && (
					<div>
						<Link ariaLabel={'Kortöversikt bankkort'} href={'/fakturor-kontobesked/bankcard'}>
							<svg width="800px" height="800px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#000000">
								<g id="SVGRepo_bgCarrier" stroke-width="0"/>
								<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
								<g id="SVGRepo_iconCarrier">
									<path d="M36.929 34.225c-.688-.315-1.654-.479-2.899-.492h-7.143v7.736h7.045c1.258 0 2.238-.171 2.938-.512c1.271-.631 1.907-1.838 1.907-3.623c0-1.509-.616-2.545-1.848-3.109" fill="#27667a"/>
									<path d="M37.008 28.211c.785-.479 1.179-1.329 1.179-2.55c0-1.352-.52-2.244-1.558-2.677c-.896-.303-2.04-.453-3.43-.453h-6.313v6.397h7.053c1.26.001 2.284-.239 3.069-.717" fill="#27667a"/>
									<path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2m11.607 40.374a7.996 7.996 0 0 1-2.055 2.283c-.927.709-2.02 1.194-3.279 1.457c-1.259.263-2.625.394-4.1.394H21.1V17.492h14.023c3.537.052 6.044 1.082 7.52 3.09c.888 1.234 1.332 2.71 1.332 4.43c0 1.771-.449 3.195-1.344 4.271c-.502.604-1.238 1.154-2.214 1.653c1.481.538 2.599 1.392 3.353 2.56c.753 1.168 1.13 2.585 1.13 4.252c-.001 1.719-.431 3.261-1.293 4.626" fill="#27667a"/>
								</g>
							</svg>
						</Link>
						<p>{'Bankkort'}</p>
					</div>
				)}
			</div>
			
			{((isBankIdUser && hasSmartCards ) ||
				(isBankIdUser && hasCreditCards)) && (
					<RightsButton
						disableOnFrozenCustomer
						className="dashboard-button"
						style={{ margin: 0 }}
						text="Beställ kort/ersättningskort"
						url="/nyttkort"
						type="button"
						userRights="CardsWrite"
						customerAllowedToWrite={customerAllowedToWriteBool}
					/>
				)
			}
			{
				/*
				<button className="dashboard-button">
				<Link ariaLabel={'Beställ nytt kort/ersättningskort'} href={'/nyttkort'}>
					{'Beställ kort/ersättningskort'}
				</Link>
			</button>
			*/
			}
		</>
	)
}

export default CardManagement
