/* eslint-disable space-before-function-paren */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/indent */
import classNames from 'classnames'
import React from 'react'
import isEmail from 'validator/es/lib/isEmail'
import isEmpty from 'validator/es/lib/isEmpty'
import isMobilePhone from 'validator/es/lib/isMobilePhone'
import { useGetOwnerInfo } from '../../hooks/queryHooks/useGetOwnerInfo'
import { usePostCustomerServiceTask } from '../../hooks/queryHooks/usePostCustomerServiceTask'
import { InputStateObject, useForm } from '../../hooks/useForm'
import Button from '../elements/Button'
import Icon from '../elements/Icon'
import InlineAlert from '../elements/InlineAlert'
import Input from '../elements/Input'
import Label from '../elements/Label'
import Link from '../elements/Link'
import ProfileBox from '../views/ProfileBox'

type Props = {}

type ContactInputs = {
  name: string
  contact: string
  message: string
}

export const ContactPage: React.FC<Props> = () => {
  const { mutate, isSuccess, isLoading, isError, reset } =
    usePostCustomerServiceTask({
      queryOptions: {
        onSettled: () => {
          resetForm()
        }
      }
  })

  // Get revelant company info
  const { data: ownerInfo } = useGetOwnerInfo()

  const { inputs, handleInputChange, isValid, validateInput, resetForm } =
    useForm<InputStateObject<ContactInputs>>({
      name: {
        value: '',
        isError: false,
        required: true,
        validationFunction: (input: string) => !isEmpty(input),
        errorMessage: 'Obligatoriskt fält'
      },
      contact: {
        value: '',
        isError: false,
        required: true,
        validationFunction: (input: string) =>
          isMobilePhone(input) || isEmail(input),
        errorMessage: 'Vänligen ange en giltig mailadress eller telefonnummer'
      },
      message: {
        value: '',
        required: true,
        isError: false,
        validationFunction: (input: string) => !isEmpty(input),
        errorMessage: 'Obligatoriskt fält'
      }
    })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const mailMessage =
      'Ärende inskickat från Mina Sidor:\n' +
      `Namn: ${inputs.name.value}\n` +
      `Kontakt via: ${inputs.contact.value}\n` +
      `Meddelande: ${inputs.message.value}`

    mutate(mailMessage)
  }

  return (
    <div className="Section BgColor--Red400 ContactPage">
      <div className="Row Row--JustifyLeft">
        <div className="Row__Inner">
          <div className="Column ContentBox Column--Lg12 ContactPage--Header">
            <div className="ContentBox__Content">
              <h1> {'Kontakta oss'} </h1>
            </div>
          </div>
          <div className="d-none Column Column--JustifyStart Column--Lg6">
            <div className="ContentBox">
              <div className="ContentBox__Content">
                <h1> {'Kontaktformulär'} </h1>
              </div>
            </div>
            <ProfileBox>
              {isSuccess || isError ? (
                <div className="ContactForm__FeedbackContainer">
                  <h5 className="ContactForm__FeedbackText">
                    {isError
                      ? 'Något gick fel. Var vänlig försök igen senare.'
                      : 'Tack! Vi återkommer så fort som möjligt.'}{' '}
                  </h5>{' '}
                  <Button onClick={reset}>
                    {isError ? 'Testa igen' : 'Nytt ärende'}
                  </Button>
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="ContactForm--TextHeader">
                    <Label text="Namn *" />
                    <span className="ContactForm--ObligatoryText">
                      {'* indikerar obligatoriskt fält'}
                    </span>
                  </div>
                  <Input
                    error={
                      inputs.name?.isError
                        ? inputs.name.errorMessage
                        : undefined
                    }
                    fullWidth
                    name="name"
                    onBlur={() => validateInput('name')}
                    onChange={handleInputChange}
                    required
                    value={inputs.name.value}
                  />
                  <Label text="Telefonnummer eller mailadress *" />
                  <Input
                    error={
                      inputs.contact?.isError
                        ? inputs.contact.errorMessage
                        : undefined
                    }
                    fullWidth
                    name="contact"
                    onBlur={() => validateInput('contact')}
                    onChange={handleInputChange}
                    required
                    value={inputs.contact.value}
                  />
                  <Label text="Beskriv ditt ärende nedan *" />{' '}
                  <Input
                    className="ContactForm__LastTextArea"
                    fullWidth
                    name="message"
                    onBlur={() => validateInput('message')}
                    onChange={handleInputChange}
                    required
                    type="textarea"
                    value={inputs.message.value}
                  />
                  <div className="ContactForm__TextAreaFooter">
                    {inputs.message?.isError && (
                      <InlineAlert
                        className={classNames('Input__Error')}
                        content={inputs.message.errorMessage}
                        type="warning"
                      />
                    )}
                    <span className="ContactForm__WordCounter">
                      {inputs.message.value.length + ' av 150 tecken använda'}
                    </span>
                  </div>
                  <div>
                    <Button
                      className="ContactForm__Button"
                      disabled={!isValid}
                      isLoading={isLoading}
                      loadingText="Skickar"
                      type="submit"
                    >
                      {'Skicka'}
                    </Button>
                  </div>
                </form>
              )}
            </ProfileBox>
          </div>
          <div className="Column Column--JustifyStart Column--Lg6 ContactPage--InfoBox" id="kontakt">
            <h4>Qstar kontaktuppgifter</h4>
            <ProfileBox>
              <div className="ContactInfoBox__Row">
                <Icon
                  className="ContactInfoBox__Icon"
                  name="phone"
                  primaryColor="red400"
                  secondaryColor="pink500"
                />
                <Link href={`tel:${ownerInfo?.phoneNumber.replace(/[-\s]/g, '')}`}>{ownerInfo?.phoneNumber}</Link>
              </div>
              <div className="ContactInfoBox__Row">
                <Icon
                  className="ContactInfoBox__Icon"
                  name="envelope"
                  primaryColor="blue500"
                  secondaryColor="green500"
                />
                <Link href={`mailto:${ownerInfo?.emailAddress}`}>{ownerInfo?.emailAddress}</Link>
              </div>
              <div className="ContactInfoBox__Row">
                <Icon
                  className="ContactInfoBox__Icon"
                  name="home"
                  primaryColor="yellow500"
                  secondaryColor="blue500"
                />
                {ownerInfo?.address1}
                <br /> {`${ownerInfo?.postalCode} ${ownerInfo?.city.charAt(0).toLocaleUpperCase()}${ownerInfo?.city.slice(1).toLowerCase()}`}
              </div>
            </ProfileBox>
          </div>

          <div className="Column Column--JustifyStart Column--Lg6 ContactPage--InfoBox" id="adress">
            <h4>Adressuppdatering</h4>
            <ProfileBox>
              <p>
                {'Din adress uppdateras automatiskt när ändring av folkbokföringsadress görs hos Skatteverket. '}
                <br />
                {'Skulle det vara något fel och adressen inte uppdaterats närmaste dagarna '}
                <Link href='#kontakt' styled>{'kontakta oss.'}</Link>
              </p>
            </ProfileBox>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactPage
