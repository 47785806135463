import type { } from '@mui/x-charts/themeAugmentation';
import React, { Fragment } from 'react';
import { useGetCustomer } from '../../../hooks/queryHooks/useGetCustomer';
import { useGetUserProfiles } from '../../../hooks/queryHooks/useGetUserProfiles';
import { int32 } from '../../../integrations/OpenAPI';
import { AuthService } from '../../../integrations/backend/v1/services/AuthService';
import Link from '../../elements/Link';

type ProfileInfoProps = {
	data?: any // TODO: Change this
}

// Data type structure from customer.emails
interface CustomerEmail {
    emailAddress: string;
    emailTypeId: string;
}
interface CustomerPhone {
	phoneId: int32;
    description: string | null;
    phoneNo: string;
    sortOrder: int32;
}

/**
 * Short info about profile overview
 *
 * @returns 
 */
const ProfileInfo: React.FC<ProfileInfoProps> = ({data}) => {	  
	const { data: customer } = useGetCustomer()
	const authenticationType = AuthService.getAuthenticationMethod()

	const { data: userProfilesPaged, isFetching } = useGetUserProfiles({
		fetchQuery: {
			PageSize: 100
		}
	})

	// ´Faktura´ epostadresser
	const invoiceEmails = customer!.emails.filter(
		(email : CustomerEmail) => email.emailTypeId === 'InvoiceStatement'
	)

	// ´Kvittokopia´ epostadresser
	const receiptCopy = customer!.emails.filter(
		(email : CustomerEmail) => email.emailTypeId === 'ReceiptCopy'
	)

	// ´Kvittokopia´ epostadresser
	const informationEmails = customer!.emails.filter(
		(email : CustomerEmail) => email.emailTypeId === 'Information'
	)

	// The paged user profiles contains by standard 2 user profiles, one for passwordLogin and one for BankID
	let connectedUsers = 1
	let connectedUsersText = 'kopplad användare'
	if ( ( userProfilesPaged?.payload.length! - 1) !== 0 ) {
		connectedUsers = userProfilesPaged?.payload.length! -1
		connectedUsersText = 'kopplade användare'
	}

	return(
		<div className="profileinfo_component">
			{customer!.emails && (
				<>
					<div className="profileinfo_component__section first-section">
						<div className="email-and-administration">
							{(invoiceEmails.length > 0 && customer!.hasActiveCreditCard ) ? (
							<>
								<p className="strong">{'Epost kopplad till faktura:'}</p>
								<div className="email-list">
									{invoiceEmails.slice( 0, 2).map((email : CustomerEmail) => (
											<Fragment key={email.emailAddress.toLowerCase()}>
												<p>{email.emailAddress.toLowerCase()}</p>
											</Fragment>
									))}
									{invoiceEmails.length > 2 && (
										<p>Plus {invoiceEmails.length - 2} {(invoiceEmails.length - 2)> 1 ? 'adresser' : 'adress'} till.</p>
									)}
								</div>
							</>
							): 
							(
							<>
								<p className="strong">{'E-postadministration:'}</p>
								<div className="email-list">
									{informationEmails.map((email : CustomerEmail) => (
										<Fragment key={email.emailAddress.toLowerCase()}>
											<p>{email.emailAddress.toLowerCase()}</p>
										</Fragment>
									))
									}
								</div>
							</>
							)
							}

							<div className="handle-emails">
							<p className="strong">{'Hantera epost för:'}</p>
								{customer?.hasActivePrepaidCard && (
								<Link className="small-link" ariaLabel={'Hantera kvittokopior'} href={'/mitt-konto#emails'} styled>
									{'Kvittokopior'}
								</Link>
								)}
								{/* Always visible because you always have a information email, TODO: is it the same as information mail on qstar card? */}
								<Link className="small-link" ariaLabel={'Hantera informationsutskick'} href={'/mitt-konto#emails'} styled>
									{'Informationsutskick'}
								</Link>

								{
								// If no 
								customer?.hasActiveCreditCard && (
								<Link className="small-link" ariaLabel={'Hantera epostfaktura'} href={'/mitt-konto#emails'} styled>
									{'Fakturor'}
								</Link>
								)
								}

							</div>
						</div>
					

						<div className="divider-flex-child"></div>

						<div className="phone">
							<p className="strong">{'Telefonnummer:'}</p>
							{customer?.phones && customer.phones.length > 0 ? (
							<>
								{customer.phones.slice(0, 3).map((phone) => (
									<Fragment key={phone.phoneId}>
										<p>{phone.phoneNo}</p>
									</Fragment>
								))}
								{customer.phones.length > 3 && (
									<p>Plus {customer.phones.length - 3} nummer till.</p>
								)}
							</>
							) : (
								<p>
									{'Du har inga telefonnummer kopplade, '}
									<Link ariaLabel={'Gå till inställningar för telefonnummerhantering'} href={'/mitt-konto'} styled>
										{'lägg till telefonnrummer'}
									</Link>
								</p>
							)}
						</div>
					</div>

					<div className="divider-horizontal"></div>

					<div className="profileinfo_component__section second-section">
					<div className="address">
							<p className="strong">{'Adress:'}</p>
							<p>{customer!.address1}</p>
							<p>{`${customer!.postalCode}, ${customer!.city}`}</p>
						</div>

						<div className="divider-flex-child less-margin"></div>

						<div className="users-auth">
							<p className="strong">{'Antal användare'}</p>
							<p>{`${connectedUsers} ${connectedUsersText}`}</p>
							<p className="strong">{'Inloggningsmetod'}</p>
							<p>{authenticationType === 'bankid' ? 'BankID' : 'Kundnummer'}</p>
						</div>
					</div>
				</>
			)}
			
		</div>
	)
}

export default ProfileInfo