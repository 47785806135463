import type { } from '@mui/x-charts/themeAugmentation';
import React from 'react';
import { useGetAccountInformation } from '../../../hooks/queryHooks/useGetAccountInformation';
import { useGetCustomer } from '../../../hooks/queryHooks/useGetCustomer';
import { useGetInvoices } from '../../../hooks/queryHooks/useGetInvoices';
import { AuthService } from '../../../integrations/backend/v1/services/AuthService';
import { formatSEK } from '../../../utils/formatSEK';
import Icon from '../../elements/Icon';
import Link from '../../elements/Link';

type paymentsBalanceProps = {
	data?: any // TODO: Change this
}

/**
 * Info about payments for credit card and balance for Smartcard
 *
 * @returns 
 */
const PaymentsBalance: React.FC<paymentsBalanceProps> = ({data}) => {	  
	const { data: customer } = useGetCustomer()
	const authenticationType = AuthService.getAuthenticationMethod()
	const invoicesQuery = useGetInvoices()
	const { data: accountInformation } = useGetAccountInformation()

	// Get all the unpaided
	const upcomingInvoices = invoicesQuery
    .data!.pages.map((page) =>
		page.payload.filter((invoice) => invoice.remainingAmount > 0)
    )
    .flat()
	//console.log('have invoices?: ', upcomingInvoices.length)
	let headingText = 'Aktuella betalningar och saldo'
	if(!customer?.hasActiveCreditCard ) {
		headingText = 'Aktuellt saldo'
	}
	if(!customer?.hasActivePrepaidCard) {
		headingText = 'Aktuella betalningar'
	}
	return(
		<div className="paymentsbalance_component">
			<p className="p-size-medium">{headingText}</p>

			<div className="paymentsbalance_component__content">
				{customer?.hasActiveCreditCard && (
				<>
					<div>
						{upcomingInvoices.length > 0 ? (
							<Icon name="engine-warning" primaryColor='red500' secondaryColor='red700' />
						) : (
							<Icon name="check-circle" primaryColor='green300' type='line' className='hasbutton' />
						)}
						<div className="balance-payments-wrapper">
							<p><strong>{customer?.isCompany ? 'Företagskort' : 'Tankkort'}</strong></p>
							<p className="dflex">
								Obetalda fakturor:
								{upcomingInvoices.length > 0 ? (
									<button className="dashboard-button">
										<Link ariaLabel={'Se obetalda fakturor'} href={'/fakturor-kontobesked'} >{'Ja'}</Link>
									</button>
									
								) : (
									<p className="italicunderline">{'Allt är betalt'}</p>
								)}
							</p>
						</div>
					</div>
					{customer?.hasActivePrepaidCard && (<div className="divider"></div>)}
				</>
				)}
				{customer?.hasActivePrepaidCard && (
				<div>
					<Icon name="wallet" primaryColor='green300' type='line' className='check' />
					<div className="balance-payments-wrapper">
						<p><strong>{`Smartkort`}</strong></p>
							<p className="dflex">Saldo: 
							<p className="dashboard-button"><Link ariaLabel={'Visa smarkort'} href={'/fakturor-kontobesked/smartkort'}>{formatSEK(accountInformation!.prepaidBalance)}</Link></p>
						</p>
					</div>
				</div>
				)}
				
			</div>
			
		</div>
	)
}

export default PaymentsBalance