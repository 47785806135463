import { BarChart } from '@mui/x-charts';
import type { } from '@mui/x-charts/themeAugmentation';
import React, { useMemo } from 'react';
import { CardType } from '../../../domain/enums/CardType';
import { useGetBankCards } from '../../../hooks/queryHooks/useGetBankCards';
import { useGetCards } from '../../../hooks/queryHooks/useGetCards';
import { useGetInvoices } from '../../../hooks/queryHooks/useGetInvoices';
import { useGetTransactions } from '../../../hooks/queryHooks/useGetTransactions';
import { getSevenYearsAgoDate } from '../../../utils/getSevenYearsAgoDate';
import Icon from '../../elements/Icon';
import ToolTipWrapper from '../../elements/ToolTipWrapper';

type SingleCardStatisticsProps = {
    data?: any // TODO: Change this
    cardTypeComponent: String
}
const sevenYearsAgoDate =  getSevenYearsAgoDate().toISOString();

const SingleCardStatistics: React.FC<SingleCardStatisticsProps> = ({ data, cardTypeComponent }) => {
    const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
    

    // Queries
    const cardsQuery = useGetCards();
    const bankCardQuery = useGetBankCards();
    const invoicesQuery = useGetInvoices();

    let helpTextSingularPlural = 'ditt'
    if ( cardsQuery.data?.tankCards?.length! > 1) {
        helpTextSingularPlural = 'dina'
    }

    // Card type configuration
    const { cardTitle, componentCardType, severalCards } = useMemo(() => {
        let cardTitle = 'Tankkort';
        let componentCardType = CardType.CreditCard;
        let severalCards = false;

        switch (cardTypeComponent) {
            case 'smartcard':
                cardTitle = 'Smartkort';
                componentCardType = CardType.SmartCard;
                severalCards = (cardsQuery.data?.smartCards?.length ?? 0) > 1;
                break;
            case 'bankcard':
                cardTitle = 'Bankkort';
                componentCardType = CardType.BankCard;
                severalCards = (bankCardQuery.data?.length ?? 0) > 1;
                break;
            default:
                severalCards = (cardsQuery.data?.tankCards?.length ?? 0) > 1;
        }

        return { cardTitle, componentCardType, severalCards };
    }, [cardTypeComponent, cardsQuery.data, bankCardQuery.data]);
    
    // Latest invoice date calculation
    const latestInvoiceDate = useMemo(() =>
        invoicesQuery.data?.pages?.[0]?.payload?.[0]?.invoiceDate?.toISOString() ?? sevenYearsAgoDate,
        [invoicesQuery.data]
    );

    // Transaction query
    const { data: transactionData, isLoading, isFetching } = useGetTransactions({
        additionalQueryKeys: [latestInvoiceDate, componentCardType],
        fetchQuery: {
            endDate: new Date().toISOString(),
            startDate: latestInvoiceDate,
            cardType: componentCardType,
            PageSize: 30
        },
        queryOptions: {
            enabled: true,
            onSuccess: (data) => {
                console.log('Transaction query success:');
            },
            onError: (error) => {
                console.error('Transaction query error');
            }
        }
    });

	//console.log('transactiondata', transactionData)
	//console.log('transactiondata pages', transactionData?.pages)
    // Check if we have any transactions
    const hasTransactions = useMemo(() => {
		//console.log('transactionData', transactionData)
        if (!transactionData?.pages?.[0]?.payload) return false;
        return transactionData.pages[0].payload.length > 0;
    }, [transactionData]);

    // Process transaction data
    const { dataSet, currentMonth, totalThisMonth } = useMemo(() => {
        if (!hasTransactions) {
            return {
                dataSet: [{ cardAmount: null, month: 'Ingen data' }],
                currentMonth: '',
                totalThisMonth: null
            };
        }

        let dataSet: any[] = [];
        let transactionMonth = '';
        let transactionMonthAmount = 0;

        const transactions = transactionData?.pages[0]?.payload || [];
        
        transactions.forEach((transaction: any) => {
            const transactionDate = new Date(transaction.dateTime.toString());
            const transactionMonthShort = monthsShort[transactionDate.getUTCMonth()];

            if (transactionMonth === '') {
                transactionMonth = transactionMonthShort;
            }

            if (transactionMonth !== transactionMonthShort) {
                dataSet.unshift({
                    cardAmount: Math.floor(transactionMonthAmount),
                    month: transactionMonth,
                });
                transactionMonth = transactionMonthShort;
                transactionMonthAmount = 0;
            }

            transactionMonthAmount += transaction.amount;
        });

        if (transactionMonth !== '') {
            dataSet.unshift({
                cardAmount: Math.floor(transactionMonthAmount),
                month: transactionMonth,
            });
        }

        // Take only last 3 months
        dataSet = dataSet.length > 3 ? dataSet.slice(-3) : dataSet;
        dataSet = dataSet.length === 0 ? [{ cardAmount: null, month: 'Ingen data' }] : dataSet;

        const currentMonth = dataSet[dataSet.length - 1].month;
        const totalThisMonth = dataSet[dataSet.length - 1].cardAmount;

        return { dataSet, currentMonth, totalThisMonth };
    }, [transactionData, monthsShort, hasTransactions]);

    /**
     * valueFormatter - Used to present a unit of the bar value when hovering.
     */
    const valueFormatter = (value: number | null): string => `${value}kr`;

    if (isLoading || isFetching) {
        return (
            <div className="dashboard__single-card-stats">
                <p className="single-card-stats-name">
                    {cardTitle + (severalCards ? ' (samtliga)' : '')}
                </p>
                <div>Laddar...</div>
            </div>
        );
    }

    return (
        <div className="dashboard__single-card-stats">
            <p className="single-card-stats-name">
                {cardTitle + (severalCards ? ' (samtliga)' : '')}
            </p>
            
            {!hasTransactions ? (
                <div className="no-transactions">
                    {`Du har inga transaktioner för ${cardTitle.toLowerCase()}`}
                    <ToolTipWrapper
                    enabled={true} // Only show tooltips on desktop
                    noHoverState={false}
                    text={`Du har inte tankat något på ${helpTextSingularPlural} ${cardTitle.toLowerCase()} ännu. När en transaktion har registrerats visas det i ett diagram.`}
                    tooltipPlacement="top"
                    >
                        <Icon
                        className="Info__Icon"
                        name="question-circle"
                        primaryColor="white"
                        secondaryColor="yellow500"
                        />
                    </ToolTipWrapper>
                </div>
            ) : (
                <>
                    <BarChart
                        dataset={dataSet}
                        xAxis={[{
                            scaleType: 'band',
                            dataKey: 'month',
                            tickPlacement: 'middle',
                            tickLabelPlacement: 'middle'
                        }]}
                        yAxis={[{ min: 0 }]}
                        series={[{ dataKey: 'cardAmount', valueFormatter, type: 'bar' }]}
                        leftAxis={null}
                        colors={['#225f9e']}
                        margin={{
                            left: 10,
                            right: 10,
                            bottom: 20,
                            top: -30,
                        }}
                        height={100}
                    />
                    <div className="spent-this-month">
                        {currentMonth && totalThisMonth !== null && `${currentMonth}: ${totalThisMonth}kr`}
                    </div>
                </>
            )}
        </div>
    );
};

export default SingleCardStatistics;
