import classNames from 'classnames'
import React from 'react'
import { useLocation } from 'react-router-dom'
import useIsBankIdUser from '../../hooks/useIsBankIdUser'
import NavTabs, { NavTabsObject } from '../elements/NavTabs'
import SuspenseLoaderWrapper from '../elements/SuspenseLoaderWrapper'
import DashboardPage from './DashboardPage'
import HandleUsersPage from './HandleUsersPage'
import ProfileSettings from './ProfileSettings'

type ProfilePageProps = {}

const ProfilePage: React.FC<ProfilePageProps> = () => {
  const { pathname } = useLocation()
  const isBankIdUser = useIsBankIdUser()

  const tabs: NavTabsObject = {
    '/': {
      label: 'Inställningar',
      component: (
        <SuspenseLoaderWrapper noPadding text="Laddar din profil...">
          <ProfileSettings />
        </SuspenseLoaderWrapper>
      )
    }
  }

  if (isBankIdUser) {
    tabs['hantera-anvandare'] = {
      label: 'Hantera användare',
      component: (
        <SuspenseLoaderWrapper noPadding text="Laddar användare...">
          <HandleUsersPage />
        </SuspenseLoaderWrapper>
      )
    }
  }

  tabs['dashboard'] = {
    label: 'Översikt',
    component: (
      <SuspenseLoaderWrapper noPadding text="Laddar översikt...">
        <DashboardPage />
      </SuspenseLoaderWrapper>
    )
  }

  // Set BGcolor for dashboard
  let standardBgColor = 'BgColor--Red400';
  //console.log(pathname)
  if ( '/mitt-konto/dashboard' === pathname ) {
    standardBgColor = `${standardBgColor} dashboard-body`;
  }

  return (
    <div
      className={classNames(`Section ${standardBgColor}`, {
        'user-profiles-page': pathname === '/mitt-konto/hantera-anvandare'
      })}
    >
      <div className="Row Row--JustifyLeft">
        <NavTabs tabs={tabs} />
      </div>
    </div>
  )
}

export default ProfilePage
