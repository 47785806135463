import React, {
	CSSProperties,
	Dispatch,
	FormEventHandler,
	SetStateAction
} from 'react'
import { useGetAuthenticationRights } from '../../hooks/queryHooks/useGetAuthenticationRights'
import {
	AuthenticationRightsGroup,
	AuthenticationRightsList
} from '../../integrations/allkort/v1/proxies/UserProfileProxy'
import {
	AuthenticationRight,
	WriteAuthenticationRight,
	allAuthenticationRights,
	allReadAuthenticationRights
} from '../../integrations/allkort/v1/schemas/LoginAuthenticationRole'
import Button from '../elements/Button'
import Loader from '../elements/Loader'
import Toggle from '../elements/Toggle'

type RightsSelectionProps = {
	onChange:
	| Dispatch<SetStateAction<AuthenticationRight[]>>
	| ((newRights: AuthenticationRight[]) => void)
	currentRights: AuthenticationRight[]
	superUserRights: boolean
	setSuperUserRights: any // Function
	className?: string
	style?: CSSProperties
	disabled?: boolean
	shouldSuspend?: boolean
}

type FormattedRight = {
	name: AuthenticationRight
	description: string
}

type FormattedRightsList = Record<AuthenticationRightsGroup, FormattedRight>

export const formatRightsList = (
	rightsList:
	| AuthenticationRightsList<WriteAuthenticationRight>
	| AuthenticationRightsList
) => {
	const rightsWithoutSuperuser:
	| AuthenticationRightsList<WriteAuthenticationRight>
	| AuthenticationRightsList = rightsList.filter(
	// @ts-ignore
	(right) => right.claimName !== 'Superuser'
	)
	const formattedRights: Record<string, FormattedRight> = {}

	for (const right of rightsWithoutSuperuser) {
		if (
			!formattedRights[right.groupingName] &&
			right.claimName.includes('Write')
		) {
		formattedRights[right.groupingName] = {
			name: right.claimName,
			description: right.description
		}
	}
	}

	return formattedRights as FormattedRightsList
}

const RightsSelection: React.FC<RightsSelectionProps> = ({
	onChange,
	currentRights,
	superUserRights,
	setSuperUserRights,
	className = '',
	style,
	shouldSuspend = true,
	disabled
}) => {
	const { data: rightsList } = useGetAuthenticationRights({
    queryOptions: { suspense: shouldSuspend ?? true }
	})

	
	const formattedRights = rightsList && formatRightsList(rightsList)

	const userHasAllRights = currentRights.length + 1 === rightsList?.length

	const updateRights: FormEventHandler<HTMLInputElement> = ({
		currentTarget: { name }
	}) => {
	const rightName = name as AuthenticationRight
	const newRights = [...currentRights] as AuthenticationRight[]

	if (newRights.includes(rightName)) {
		newRights.splice(newRights.indexOf(rightName), 1)
		setSuperUserRights(false) // If any of the other values are disabled also diable the proxy `fullmakt` toggle
	} else {
		newRights.push(rightName)
	}

	onChange(newRights)
	}

	// Changes useState for proxy
	const updateProxy = () => {
		setSuperUserRights( !superUserRights )
		toggleAllRights() // When `fullmakt` is toggled all the toggles should also be toggled
	}

	const toggleAllRights = () => {
		if (userHasAllRights) {
			onChange([...allReadAuthenticationRights])
			// TODO: This will be true for future implementation. Set the now hidden toggle to true.
			//setSuperUserRights(false) // Manually set the proxy toggle too
		} else {
			onChange([...allAuthenticationRights])
			//setSuperUserRights(true) // Manually set the proxy toggle too
		}
	}
	return (
	<div className={`RightsSelection ${className}`} style={style}>
		<h5 className="RightsSelection__title">
		{'Redigeringsrättigheter'}{' '}
		{rightsList && (
			<Button
			className="RightsSelection__toggle-all-button"
			disabled={disabled}
			onClick={toggleAllRights}
			>
			{userHasAllRights
				? 'Ta bort alla rättigheter'
				: 'Fyll i alla rättigheter'}
			</Button>
		)}
		</h5>
		<p className="RightsSelection__description">
		{
			'Välj nedan vilka typer av data användaren ska ha rättighet att redigera'
		}
		</p>
		{rightsList ? (
		<div className="RightsSelection__rights">
			{formattedRights &&
			Object.keys(formattedRights)
				.sort()
				.map((category) => {
				const rightCategory = category as AuthenticationRightsGroup
				const rightName = formattedRights[rightCategory].name
				const rightDescription = formattedRights[rightCategory].description
				
				// The text we get from the api is not always correct
				let labelTextCorrection = ''
				let infoTextCorrection = ''
				if ( rightCategory === 'Kort' ) {
					labelTextCorrection = 'Qstarkort'
					infoTextCorrection = 'Rättighet att lägga till/ändra/spärra smart-/tankkort'
				}

				return (
					<div
					className="RightsSelection__rights-group"
					key={rightCategory}
					>
						<Toggle
							checked={currentRights.includes(rightName)}
							disabled={disabled}
							helpText={`Användaren kan${
							currentRights.includes(rightName) ? ' ' : ' inte '
							}redigera ${(infoTextCorrection.length > 0 ? infoTextCorrection.toLocaleLowerCase() : rightCategory.toLowerCase())}`}
							infoText={infoTextCorrection.length > 0 ? infoTextCorrection : rightDescription}
							label={labelTextCorrection.length > 0 ? labelTextCorrection : rightCategory}
							name={rightName}
							onChange={updateRights}
						/>
					</div>
				)
			})}
			{ false &&
				/**
				 * TODO: This toggle is still left for future implementation.
				 * To be able to add superuser rights without using the current rights list we add a toggle
				 * `Fullmakt` and it gets passed to the user creation functions and overrides to set a new user
				 * with a super user role without fetching the data from the superuser list and instead using more
				 * user firendly text.
				 */
				<div
					className="RightsSelection__rights-group"
					key={'Fullmakt'}
					>
						<Toggle
							checked={superUserRights} // Get useState for fullmakt
							disabled={disabled}
							helpText={`Användaren får${
							superUserRights ? ' ' : ' inte '
							}fullmakt till kontot`}
							infoText={'Rättighet att göra allt som dig som administratör, inkluderar ta bort konto mm.	'}
							label={'Fullmakt'}
							name={'proxy'}
							onChange={updateProxy}
						/>
					</div>
			}

		</div>
		) : (
		<Loader loaderColor="#000000" noPadding text="Laddar Rättigheter..." />
		)}
	</div>
	)
}

export default RightsSelection
