import classNames from 'classnames'
import React from 'react'
import { BankCard } from '../../integrations/allkort/v1/schemas/Card'

type BankCardRowProps = { card: BankCard }

const BankCardRow: React.FC<BankCardRowProps> = ({ card }) => {
  return (
    <tr
      className={classNames('CardTable__Row', {
        'CardTable__Row--BankCard': true
      })}
    >
      <td>
        <span className="CardTable__CardType">
          <h6 className="Card__Header">
            {'B'}
            <span className="Card__DesktopText">{'ankkort'}</span>
          </h6>
        </span>
      </td>
      <td>
        <div className="Card__CardReference">
          {card.maskedPan.replaceAll('X', '*')}
        </div>
      </td>
      <td className="CardTable__ColActions"></td>
    </tr>
  )
}

export default BankCardRow
