import PropTypes from 'prop-types'
import React, { CSSProperties } from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'

export type LoaderProps = {
  noPadding?: boolean
  noText?: boolean
  text?: string
  style?: CSSProperties
  loaderColor?: string
  loaderStyles?: CSSProperties
}

const Loader: React.FunctionComponent<LoaderProps> = ({
  noPadding,
  noText,
  text,
  loaderColor = '#FFFFFF',
  style,
  loaderStyles
}) => {
  const loader = (
    <>
      {!noText && <h4 style={loaderStyles}>{text || 'Hämtar din data...'}</h4>}
      <ScaleLoader color={loaderColor} />
    </>
  )

  if (noPadding && style) {
    return <div style={style}>{loader}</div>
  } else if (noPadding) {
    return loader
  }

  return (
    <div className="Section BgColor--Red400" style={style}>
      <div className="Row Row--JustifyCenter">
        <div className="Row__Inner">
          <div className="Column">
            <div className="ContentBox">
              <div className="ContentBox__Content loader">{loader}</div>
            </div>
          </div>
          <div className="Column Column--JustifyStart Column--Lg0 Column--Md0 Column--Sm12" />
        </div>
      </div>
    </div>
  )
}

Loader.propTypes = {
  noPadding: PropTypes.bool,
  text: PropTypes.string
}

export default Loader
