/* eslint-disable react/no-multi-comp */
/* eslint-disable no-negated-condition */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-key */
import React from 'react'
import { EmailTypes } from '../../../integrations/allkort/v1/enums/EmailType'
import { Email } from '../../../integrations/allkort/v1/schemas/Email'

import CustomerProfileEmailsEdit from './CustomerProfileEmailsEdit'
import { CustomerProfileEmailsView } from './CustomerProfileEmailsView'
import ProfileBox from './ProfileBox'

export const mapEmailsToEmailEdit = (emails: Email[]) => {
  const mappedEmails = [] as EmailEdit[]
  const allUniqueEmails = new Set(emails.map((email) => email.emailAddress))

  allUniqueEmails.forEach((emailAddress) => {
    const emailAdressObject = {
      address: emailAddress,
      types: [] as EmailTypes[],
      errorMessage: ''
    }

    emails.forEach((email) => {
      if (email.emailAddress === emailAddress) {
        emailAdressObject.types.push(email.emailTypeId)
      }
    })

    mappedEmails.push(emailAdressObject)
  })

  return mappedEmails
}

export type EmailEdit = {
  address: string
  types: EmailTypes[]
  id?: number
  errorMessage?: string
}

type Props = {}

export const CustomerProfileEmails: React.FC<Props> = (props) => {
  const [editMode, setEditMode] = React.useState<boolean>(false)

	return (
		<>
			
			<div className="mobile-scroll-right-shadow">
				<ProfileBox className="ProfileEmailBox" id="emails">
          <h5>{'Mailadresser - Qstarkort'}</h5>
					{editMode ? (
					<CustomerProfileEmailsEdit setEditMode={setEditMode} />
					) : (
					<CustomerProfileEmailsView setEditMode={setEditMode} />
					)}
				</ProfileBox>
			</div>
		</>
)
}
