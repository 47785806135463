import { authorizedFetch } from './authorizedFetch'

export const authorizedGet = async <R>(
  path: string,
  query?: unknown
): Promise<R> => {
  const response = await authorizedFetch('GET', path, { query })

  const responseBody = await response.json()
  return responseBody as unknown as R
}
