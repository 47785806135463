/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetAccountInformation } from '../../../hooks/queryHooks/useGetAccountInformation'
import { useGetCustomer } from '../../../hooks/queryHooks/useGetCustomer'
import { usePostCustomerServiceTask } from '../../../hooks/queryHooks/usePostCustomerServiceTask'
import { currentUserHasRight } from '../../../utils/currentUserHasRight'
import Button from '../../elements/Button'
import Loader from '../../elements/Loader'
import CustomerServiceModal from './CustomerServiceModal'
import { DeleteAccountContainer } from './DeleteAccountContainer'
import DeleteAccountModal from './DeleteAccountModal'
import { CheckboxAlternative } from './HasRemainingBalance'
import { BankInfo } from './ReclaimBalance'

type DeleteAccountPageProps = {}

const DeleteAccountPage: React.FC<DeleteAccountPageProps> = () => {
  const { data: customer } = useGetCustomer()
  const { data: accountInformation } = useGetAccountInformation()
  const customerServiceMessageMutation = usePostCustomerServiceTask()
  const navigate = useNavigate()

  const [bankInfo, setBankInfo] = useState<BankInfo | undefined>(undefined)
  const [checkedValue, setCheckedValue] = useState<CheckboxAlternative | null>(
    null
  )
  const [confirmModal, setConfirmModal] = useState<boolean>(false)
  const [customerModal, setCustomerModal] = useState<boolean>(false)
  const [hasSentDeleteAccountRequest, setHasSentDeleteAccountRequest] =
    useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [contactString, setContactString] = useState<string>('')

  const createMessage = (newBankInfo?: BankInfo): string => {
    let newMessage =
      'Ansökan om att avsluta konto:\n' +
      `Kundnummer: ${customer!.customerNo}\n` +
      `Namn: ${customer!.name}\n`

    if (checkedValue) {
      newMessage +=
        checkedValue === 'reclaim' && newBankInfo
          ? `Få utbetalt till konto: ${newBankInfo.clearingNo}-${newBankInfo.accountNo}`
          : 'Skänk till välgörenhet'
    }

    return newMessage
  }

  const handleDeleteAccountClick = (newBankInfo?: BankInfo) => {
    const newMessage = createMessage(newBankInfo)
    setBankInfo(newBankInfo)
    setConfirmModal(true)
    setMessage(newMessage)
  }

  const handleOnConfirmDelete = () => {
    setConfirmModal(false)
    customerServiceMessageMutation.mutate(message)

    if (hasBalance()) {
      setHasSentDeleteAccountRequest(true)
    } else {
      navigate('/loggaut?returnPath=konto-avslutat')
      navigate('/konto-avslutat')
    }
  }

  const handleCustomerDelete = () => {
    const newMessage =
      'Ansökan om att avsluta konto:\n' +
      `Kundnummer: ${customer!.customerNo}\n` +
      `Namn: ${customer!.name}\n` +
      `Kontakt via: ${contactString}`

    customerServiceMessageMutation.mutate(newMessage)

    if (hasBalance()) {
      setHasSentDeleteAccountRequest(true)
    }
  }

  const hasBalance = () => {
    return accountInformation ? accountInformation.prepaidBalance > 0 : false
  }

  const phoneNo = customer?.phones.at(0)?.phoneNo ?? ''

  if (!currentUserHasRight('Superuser')) {
    navigate('/mitt-konto')
  }

  if (!customer || !accountInformation) {
    return <Loader text="Laddar sidan..." />
  }

  return (
    <>
      {confirmModal && (hasBalance() ? checkedValue : true) && (
        <DeleteAccountModal
          bankInfo={bankInfo}
          checkedValue={checkedValue!}
          hasBalance={hasBalance()}
          onCloseModal={() => setConfirmModal(false)}
          onConfirmDelete={handleOnConfirmDelete}
        />
      )}
      {customerModal && (
        <CustomerServiceModal
          contactString={contactString}
          onChangeContact={setContactString}
          onCloseModal={() => setCustomerModal(false)}
          onConfirmDelete={handleCustomerDelete}
          warning
        />
      )}
      <div className="DeleteAccountPage Section BgColor--Red400">
        <div className="Row">
          <div className="Row__Inner">
            <div className="Column Column--AlignStart Column--Lg7">
              {hasSentDeleteAccountRequest ? (
                <>
                  <h3>{'Ärendet har skickats till kundservice'}</h3>
                  {hasBalance() ? (
                    <>
                      <p>
                        {
                          'Ditt konto kommer att raderas efter att kundservice har behandlat ärendet.'
                        }
                      </p>
                      <Button text="Stäng" url="/loggaut" />
                    </>
                  ) : (
                    <p>{'Ditt konto är nu avslutat.'}</p>
                  )}
                </>
              ) : (
                <DeleteAccountContainer
                  accountInformation={accountInformation}
                  hasBalance={hasBalance()}
                  onConfirmDelete={(bankInfoVal) =>
                    handleDeleteAccountClick(bankInfoVal)
                  }
                  phoneNo={phoneNo}
                  selectedValue={(checkedValueVal) =>
                    setCheckedValue(checkedValueVal)
                  }
                />
              )}
              {!hasSentDeleteAccountRequest && (
                <div className="DeleteAccountPage__CustomerService">
                  <em>
                    <p>
                      {
                        'Om du istället vill bli kontaktad av kundservice för att få hjälp med att avsluta ditt konto kan du '
                      }
                      <a
                        onClick={() =>
                          setCustomerModal((oldValue) => !oldValue)
                        }
                        onKeyDown={(event) => {
                          if (
                            event.key === 'Enter' ||
                            event.key === ' ' ||
                            event.key === 'Spacebar'
                          ) {
                            setCustomerModal((oldValue) => !oldValue)
                          }
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {'klicka här.'}
                      </a>
                    </p>
                  </em>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteAccountPage
