import React from 'react'
import { useGetCustomer } from '../../../hooks/queryHooks/useGetCustomer'
import Link from '../../elements/Link'
import ProfileBox from './ProfileBox'

type Props = {}

export const CustomerProfileInformation: React.FC<Props> = () => {
  const { data: customer } = useGetCustomer()

  return (
    <div>
      <ProfileBox className= "profile-customer-info">
        <h5>{'Kunduppgifter'}</h5>
        <div>
          <span style={{ marginRight: '2rem' }}>
            <b>{'Kundnummer'}</b>
            <p>{customer!.customerNo}</p>
          </span>
          <span>
            <b>{'Postadress'}</b>
            <p>
              {customer!.address1}
              <br />
              {customer!.postalCode}
              {', '}
              {customer!.city}
            </p>
          </span>
          <small style={{ width: '100%' }}>
            {'Din adress uppdateras automatiskt, '}
            <Link href="/kontakt#adress" styled>
              {'läs mer.'}
            </Link>
          </small>
        </div>
      </ProfileBox>
    </div>
  )
}
