import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import { CustomTooltip } from './CustomTooltip'

type Props = {
  children?: React.ReactNode
  className?: string
  text: string
  title?: string
  toolTip?: string
  vertical?: boolean
  style?: CSSProperties
}

const Label: React.FunctionComponent<Props> = ({
  children,
  className,
  text,
  title,
  toolTip = '',
  vertical,
  style
}) => {
  return (
    <label
      className={classNames('Label', vertical && 'Label--Vertical', className)}
      style={style}
      title={title}
    >
      <div className="Label__Text" >
        {text}
        {toolTip.length > 1 && (
              <CustomTooltip text={toolTip} />
            ) }
      </div>
      {children}
    </label>
  )
}

export default Label
