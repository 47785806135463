import React from 'react'
import useIsBankIdUser from '../../../hooks/useIsBankIdUser'
import Button from '../../elements/Button'
import ExternalLinkIcon from '../../elements/ExternalLinkIcon'
import Info from '../../elements/Info'
import Link from '../../elements/Link'

type DeleteAccountButtonsProps = {
  canDelete: boolean
  onConfirmDelete: () => void
}

const DeleteAccountButtons: React.FC<DeleteAccountButtonsProps> = (props) => {
  const isBankIdUser = useIsBankIdUser()
  const { canDelete, onConfirmDelete } = props

  return (
    <>
      {isBankIdUser ? (
        <div>
          <Button
            className={`${canDelete ? '' : 'ButtonAccessibilityDisabled'}`}
            disabled={!canDelete}
            onClick={onConfirmDelete}
            text="Avsluta konto"
          />
          <Button
            className="Profile__CancelButton"
            text="Avbryt"
            url="/mitt-konto"
          />
        </div>
      ) : (
        <Info small type="info">
          <span>{'För att avsluta ditt konto: '}</span>
          <Link href="/login-bankid">
            {'Logga in med BankID '}
            <ExternalLinkIcon />
          </Link>
          <span>{'eller kontakta kundservice.'}</span>
        </Info>
      )}
    </>
  )
}

export default DeleteAccountButtons
