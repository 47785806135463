import classNames from 'classnames'
import React from 'react'
import { useGetBankCards } from '../../hooks/queryHooks/useGetBankCards'
import { useGetCards } from '../../hooks/queryHooks/useGetCards'
import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer'
import { useGetInvoices } from '../../hooks/queryHooks/useGetInvoices'
import useIsBankIdUser from '../../hooks/useIsBankIdUser'
import { useUserRights } from '../../hooks/useUserRights'
import Icon from '../elements/Icon'
import Link from '../elements/Link'
import { useCustomerStatus } from '../hocs/GetCustomerStatus'
import CardManagement from '../views/Dashboard/CardManagement'
import PaymentsBalance from '../views/Dashboard/PaymentsBalance'
import ProfileInfo from '../views/Dashboard/ProfileInfo'
import SingleCardStatistics from '../views/Dashboard/SingleCardStatistics'

type Props = {}

const ProfileSettings: React.FC<Props> = () => {
	const { data: customer } = useGetCustomer()
	const { data: cards } = useGetCards()
	const { data: bankcards } = useGetBankCards()
	const { customerAllowedToWriteBool } = useCustomerStatus( customer );
	const invoicesQuery = useGetInvoices()
	//console.log('customer:', customer)
	

	const hasSmartCards = cards!.smartCards.length > 0
	const hasCreditCards = cards!.tankCards.length > 0
	const hasBankCards = customer!.bankCardCustomer === true
	const isCardCustomer = ( hasBankCards || hasCreditCards || hasSmartCards )

	const { isSuperUser } = useUserRights()
	const isBankIdUser = useIsBankIdUser()
	//const customerAllowedToEdit = helpers.customerAllowedToWrite()

	// Prettify name, sometimes it is capitalized
	const prettyfyName = (name: string) => {
		if ( ! name ) {
			return 'Profile name'
		}
		const newName = name.split(' ').map( (singleName) => (
			singleName = singleName.charAt(0).toUpperCase() + singleName.slice(1).toLowerCase()
		) ).join(' ')
		return newName;
	}

	let dashboardWrapperClasses = ''
	if ( !customer?.hasActiveCreditCard && !customer?.hasActivePrepaidCard ) {
		dashboardWrapperClasses = 'no-qstar-cards'
	}
	return (
	<div className={classNames(
		'Row__Inner',
		'dashboard',
		dashboardWrapperClasses)}>
		<div className="dashboard__title">
			<svg width="800px" height="800px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="#000000">
				<g id="SVGRepo_bgCarrier" stroke-width="0"/>
				<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
				<g id="SVGRepo_iconCarrier">  
					<title/> 
					<g id="fill"> <circle className="cls-1" cx="16" cy="7.48" r="5.48"/> 
						<path className="cls-2" d="M23.54,30H8.46a4.8,4.8,0,0,1-4.8-4.8h0A10.29,10.29,0,0,1,13.94,14.92h4.12A10.29,10.29,0,0,1,28.34,25.2h0A4.8,4.8,0,0,1,23.54,30Z"/> 
					</g> 
				</g>
			</svg>
			<div>
				<h1>{prettyfyName(customer!.name)}</h1>
				<p>Din personliga överblicksvy</p>
			</div>
			<div className="date">
				{new Date().toLocaleDateString('en-GB')}
				<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"/>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
					<g id="SVGRepo_iconCarrier"> 
						<path d="M6.94028 2C7.35614 2 7.69326 2.32421 7.69326 2.72414V4.18487C8.36117 4.17241 9.10983 4.17241 9.95219 4.17241H13.9681C14.8104 4.17241 15.5591 4.17241 16.227 4.18487V2.72414C16.227 2.32421 16.5641 2 16.98 2C17.3958 2 17.733 2.32421 17.733 2.72414V4.24894C19.178 4.36022 20.1267 4.63333 20.8236 5.30359C21.5206 5.97385 21.8046 6.88616 21.9203 8.27586L22 9H2.92456H2V8.27586C2.11571 6.88616 2.3997 5.97385 3.09665 5.30359C3.79361 4.63333 4.74226 4.36022 6.1873 4.24894V2.72414C6.1873 2.32421 6.52442 2 6.94028 2Z" fill="#ff80d7"/> <path opacity="0.5" d="M21.9995 14.0001V12.0001C21.9995 11.161 21.9963 9.66527 21.9834 9H2.00917C1.99626 9.66527 1.99953 11.161 1.99953 12.0001V14.0001C1.99953 17.7713 1.99953 19.6569 3.1711 20.8285C4.34267 22.0001 6.22829 22.0001 9.99953 22.0001H13.9995C17.7708 22.0001 19.6564 22.0001 20.828 20.8285C21.9995 19.6569 21.9995 17.7713 21.9995 14.0001Z" fill="#ff80d7"/> <path d="M18 17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17C16 16.4477 16.4477 16 17 16C17.5523 16 18 16.4477 18 17Z" fill="#ff80d7"/> <path d="M18 13C18 13.5523 17.5523 14 17 14C16.4477 14 16 13.5523 16 13C16 12.4477 16.4477 12 17 12C17.5523 12 18 12.4477 18 13Z" fill="#ff80d7"/> <path d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z" fill="#ff80d7"/> <path d="M13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13C11 12.4477 11.4477 12 12 12C12.5523 12 13 12.4477 13 13Z" fill="#ff80d7"/> <path d="M8 17C8 17.5523 7.55228 18 7 18C6.44772 18 6 17.5523 6 17C6 16.4477 6.44772 16 7 16C7.55228 16 8 16.4477 8 17Z" fill="#ff80d7"/> <path d="M8 13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13C6 12.4477 6.44772 12 7 12C7.55228 12 8 12.4477 8 13Z" fill="#ff80d7"/>
					</g>
				</svg>
			</div>
		</div>

		{/* Dashboard content */}

		{/**
		 * Shows Balance of smart card.
		 * Shows payments of credit card.
		 * Only visible if you have any of the cards.
		 */}
		{ (customer?.hasActiveCreditCard || customer?.hasActivePrepaidCard ) && (
		<div className="dashboard__paymentsbalance">
			<PaymentsBalance />
		</div>
		)}
		

		<div className="dashboard__connected-cards">
			<CardManagement />
		</div>

		{/*<div className="dashboard__chart"> */ }
			{
				/**
				 * Visar diagram som exempel
				 *
				<GraphSpend />*/
			}
			{
			/*<p className="p-size-medium">Totalt tankade liter Oktober</p>
			<img src="https://i.ibb.co/pb1YxGD/Screenshot-2024-10-07-at-14-19-30.png" alt="Screenshot-2024-10-07-at-14-19-30" />
			*/
			}


		{/*</div>*/ }
		
		<div className="dashboard__stats-wrapper">
			<h4>
				<Icon name="chart-bar" primaryColor='blue300' />
				{'Din statistik'}
			</h4>
			<div className="stats-wrapper-inner">
				{/* Tankkort */}
				{hasCreditCards && (
					<SingleCardStatistics
					cardTypeComponent={'creditcard'}
					/>
				)}
				{/* Smartcard */}
				{hasSmartCards && (<SingleCardStatistics
				cardTypeComponent={'smartcard'}
				/>
				)}

				{/* Bankcard */}
				{/*console.log('hasbankcard', hasBankCards)*/}
				{hasBankCards && (<SingleCardStatistics
				cardTypeComponent={'bankcard'}
				/>)
				}
			</div>
			<button className="dashboard-button">
				<Link ariaLabel={'Transaktioner'} href={'/fakturor-kontobesked'}>
					{'Se alla transaktioner'}
				</Link>
			</button>
		</div>

		<div className="dashboard__general-profile">
			{
				/**
				 * Email adresser
				 * telefonnrummer kopplade
				 * antal användare
				 * Antal smartkort
				 * Antal Bankkort
				 * Registread adress
				 */
			}
			<p className="p-size-medium">Profil</p>
			<div className="profile-info-wrapper">
				<ProfileInfo />
			</div>

			<button className="dashboard-button">
				<Link ariaLabel={'Profilinställningar'} href={'/mitt-konto'}>
					{'Inställningar'}
				</Link>
			</button>
		</div>

		<div className="dashboard__info">
			<div className="dashboard__info-text-wrapper">
				<Icon name={'stars'}></Icon>
				<div className="info-right">
					<p>
						{'Välkommen till översiktsvyn som är under utveckling, vi kommer att uppdatera och förändra den över tid. Håll ögonen öppna för nya funktioner och vyer.'}
					</p>
					<p>
						{'Vid problem eller feedback kontakta gärna '}<Link href={'/kontakt'} ariaLabel={'Kundservice'} styled>kundservice</Link>
					</p>
				</div>
				
			</div>
		</div>
	</div>
	)
}
export default ProfileSettings
