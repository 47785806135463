export const queryKeys = {
  useGetAuthenticationRights: 'authentication-rights',
  useGetInvoices: 'invoices-paged',
  useGetUserProfiles: 'user-profiles',
  useGetCustomer: 'customer',
  useGetTransactions: 'transactions',
  useGetAgreementApprovals: 'agreement-approvals',
  useGetAgreements: 'agreements',
  useGetCards: 'cards',
  useGetAccountInformation: 'account-information',
  useGetSmartCardHistory: 'smart-card-history',
  useGetSmartCardBalanceHistory: 'smart-card-balance-history',
  useGetCurrentUser: 'current-user',
  useGetStatements: 'statements',
  useGetWordpressBanners: 'banners',
  useGetFooter: 'footer',
  useGetBankCards: 'bankcards',
  useGetBankCardsReceips: 'receips',
  useGetOwnerInfo: 'useGetOwnerInfo'
} as const

export type QueryKeys = typeof queryKeys

export type QueryKey = typeof queryKeys[keyof typeof queryKeys]
