import classNames from 'classnames'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { CardModel } from '../../domain/models/CardModel'
import {
  useDevCardValues,
  useGetCards
} from '../../hooks/queryHooks/useGetCards'
import useIsBankIdUser from '../../hooks/useIsBankIdUser'
import { CardEditState } from './Card'
import CardChangeName from './CardChangeName'
import CardDisable from './CardDisable'
import { CardMenu } from './CardMenu'
import CardNewPin from './CardNewPin'
import CardReplacement from './CardReplacement'
import { DisableLastCardModal } from './DisableLastCardModal'

type Props = {
  model: CardModel
}

const CardRow: React.FC<Props> = ({ model: card }) => {
  const { values: devValues } = useDevCardValues()
  const isBankIdUser = useIsBankIdUser()
  const cardsQuery = useGetCards()
  const [editState, setEditState] = useState(CardEditState.Initial)
  const allActiveCards = [
    ...cardsQuery.data!.smartCards,
    ...cardsQuery.data!.tankCards
  ].filter((value) => !value.disabled)
  const isLastActiveCard = devValues?.isLastCard ?? allActiveCards.length === 1

  const handleEditChangeNameClick = (): void => {
    if (isBankIdUser) {
      setEditState(CardEditState.ChangeName)
    } else {
      window.location.href = '/login-bankid'
    }
  }

  const handleEditChangePinClick = (): void => {
    if (isBankIdUser) {
      setEditState(CardEditState.ChangePin)
    } else {
      window.location.href = '/login-bankid'
    }
  }

  const handleEditReplacementCardClick = (): void => {
    if (isBankIdUser) {
      setEditState(CardEditState.ReplacementCard)
    } else {
      window.location.href = '/login-bankid'
    }
  }

  const handleEditDisableCardClick = (): void => {
    setEditState(CardEditState.DisableCard)
  }

  const handleCancel = (): void => {
    setEditState(CardEditState.Initial)
  }

  let cardContainerClasses = 'CardTable__CardType';
  if (card?.isCompany) {
    cardContainerClasses += ' companycard'
  }

  return editState === CardEditState.Initial ||
    (editState === CardEditState.DisableCard && isLastActiveCard) ? (
    <>
      <tr className={getCardClass(card)}>
        <td>
          <span className={cardContainerClasses}>
            <h6 className="Card__Header">
              {card.isCompany ? (
                <>
                  <span className="Card__DesktopText">{' Företag'}</span>
                  <span className="Card__MobileText">{'F'}</span>
                </>
              ) : (
                (card.isSmartCard && !card.isCompany) ? (
                  <>
                    {'S'}
                    <span className="Card__DesktopText">{'martkort'}</span>
                  </>
                ) : (
                  <>
                    {'T'}
                    <span className="Card__DesktopText">{'ankkort'}</span>
                  </>
                )
              )}
              {}
              
            </h6>
          </span>
        </td>
        <td>
          <div className="Card__CardReference">{card.cardReference1}</div>
          <div className="Card__CardReference Card__CardReference--Secondary">
            {card.cardReference2}
          </div>
        </td>
        <td className="CardTable__ColActions">
          {card.disabled ? (
            <span className="CardTable__DisabledText">
              {'Spärrat '}
              <span className="CardTable__DisabledDate">
                {format(card.disabledDate ?? 0, 'yyyy-MM-dd')}
              </span>
            </span>
          ) : (
            <div className="CardTable__CardButtons">
              <CardMenu
                onChangeNameClick={handleEditChangeNameClick}
                onChangePinClick={handleEditChangePinClick}
                onChangeReplacementCardClick={handleEditReplacementCardClick}
                onDisableCardClick={handleEditDisableCardClick}
              />
            </div>
          )}
        </td>
      </tr>
      {editState === CardEditState.DisableCard && (
        <DisableLastCardModal card={card} onClose={handleCancel} />
      )}
    </>
  ) : (
    <tr className="CardTable__Row">
      <td colSpan={6}>
        {editState === CardEditState.DisableCard && (
          <CardDisable card={card} onCancel={handleCancel} />
        )}
        {editState === CardEditState.ChangePin && (
          <CardNewPin card={card} onCancel={handleCancel} />
        )}
        {editState === CardEditState.ReplacementCard && (
          <CardReplacement card={card} onCancel={handleCancel} />
        )}
        {editState === CardEditState.ChangeName && (
          <CardChangeName card={card} onCancel={handleCancel} />
        )}
      </td>
    </tr>
  )
}

const getCardClass = ({ disabled, isSmartCard }: CardModel) =>
  classNames('CardTable__Row', {
    'CardTable__Row--Disabled': disabled,
    'CardTable__Row--SmartCard': isSmartCard
  })

export default CardRow
