import React, { Fragment } from 'react'
import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer'
import { EmailType } from '../../integrations/allkort/v1/enums/EmailType'
import Link from '../elements/Link'

type Props = {}

const BalanceInformation: React.FC<Props> = () => {
  const { data: customer } = useGetCustomer()

  const invoiceEmails = customer!.emails.filter(
    (email) => email.emailTypeId === EmailType.InvoiceStatement
  )

  let address
  let paymentMethodString
  if (customer!.statementMailingDeliveryType === 'PaperMailing') {
    address =
      customer!.address1 + ', ' + customer!.postalCode + '  ' + customer!.city
    paymentMethodString = 'papper'
  } else if (customer!.statementMailingDeliveryType === 'Email') {
    address = invoiceEmails
      ? invoiceEmails.map((email) => (
          <Fragment key={email.emailAddress}>
            <span>{email.emailAddress}</span>
            <br />
          </Fragment>
        ))
      : 'Du har ingen mailadress inställd för mailfakturor'
    paymentMethodString = 'email'
  }

  return (
    <div className="PaymentOptions">
      <div className="PaymentOptions__PaymentInfo">
        <div className="PaymentOptions__Main PaymentOptions__Area">
          <div className="PaymentOptions__Header">
            {address && paymentMethodString ? (
              <p>
                {'Kontobesked skickas varje månad via '}
                {paymentMethodString}
                {' till: '}
                <br />
                <b>{address}</b>
              </p>
            ) : (
              <p>
                <i>{'Du har inget leveranssätt valt för dina kontobesked.'}</i>
              </p>
            )}
          </div>
          <div className="PaymentOptions__Header">
            <p style={{ marginBottom: 0 }}>
              {'För att ändra utskicksformat, gå till '}
              <Link href="/mitt-konto#statement-settings" styled>
                {'Inställningar'}
              </Link>
              {'.'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BalanceInformation
