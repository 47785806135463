import classNames from 'classnames';
import React, { CSSProperties } from 'react';

type ProfileBoxProps = { style?: CSSProperties; className?: string, id?: string }

const ProfileBox: React.FC<ProfileBoxProps> = ({
  children,
  style,
  className,
  id
}) => {
  return (
    <div
      className={classNames('ProfileBox__Main ProfileBox__Area', className)}
      style={style}
      id={id}
    >
      {children}
    </div>
  )
}

export default ProfileBox
